<template>
   <v-menu
      v-model="menuFecha"
      :disabled="readonly"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
   >
      <template #activator="{ on }">
         <v-text-field
            ref="txtFecha"
            slot="activator"
            v-model="fechaFormat"
            v-mask="month ? '####/##' : '##/##/####'"
            :readonly="readonly"
            :label="label"
            :style="txtStyle"
            :clearable="clearable"
            clear-icon="fa-times"
            color="primary"
            :prepend-icon="prependIcon"
            :append-icon="appendIcon"
            :rules="rules"
            v-on="on"
            @click:clear="fechaInner = null"
            @focus="focusFecha"
            @blur="blurFecha"
            @keyup.enter="blurTxtFecha"
         ></v-text-field>
      </template>

      <v-date-picker
         ref="picker"
         v-model="fechaInner"
         :readonly="readonly"
         :allowed-dates="allowed"
         :min="min"
         :max="max"
         :type="month ? 'month' : 'date'"
         color="primary"
         scrollable
         locale="es-419"
         @input="menuFecha = false"
      ></v-date-picker>
   </v-menu>
</template>

<script>
import formatters from '../../mixins/formattersLib'
import { mask } from 'vue-the-mask'

export default {
   directives: {
      mask
   },
   mixins: [formatters],
   props: {
      date: {
         type: String,
         default: null
      },
      activePicker: {
         type: String,
         default: ''
      },
      min: {
         type: String,
         default: ''
      },
      max: {
         type: String,
         default: ''
      },
      label: {
         type: String,
         default: 'Fecha'
      },
      month: {
         type: Boolean,
         default: false
      },
      year: {
         type: Boolean,
         default: false
      },
      readonly: {
         type: Boolean,
         default: false
      },
      allowed: {
         type: Function,
         default: null
      },
      txtStyle: {
         type: String,
         default: ''
      },
      clearable: {
         type: Boolean,
         default: false
      },
      prependIcon: {
         type: String,
         default: 'far fa-calendar-minus'
      },
      appendIcon: {
         type: String,
         default: ''
      },
      rules: {
         type: Array,
         default: () => []
      }
   },
   data: (vm) => ({
      fechaInner: vm.date,
      menuFecha: false,
      fechaFormat: vm.month
         ? vm.formatYearMonth(vm.date)
         : vm.formatDate(vm.date),
      fechaSaved: ''
   }),
   watch: {
      menuFecha(val) {
         if (this.year) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
         }
      },
      date(val) {
         if (val !== '') {
            this.fechaInner = val
         }
      },
      fechaInner(val) {
         if (val !== '') {
            if (this.month) {
               this.fechaFormat = this.formatYearMonth(val)
            } else {
               this.fechaFormat = this.formatDate(val)
            }
            this.$emit('updateFecha', val)
         }
      }
   },
   methods: {
      focusFecha() {
         if (!this.readonly) {
            this.fechaSaved = this.fechaFormat
            this.fechaFormat = ''
         }
      },
      blurFecha() {
         if (this.fechaFormat == '') {
            this.fechaFormat = this.fechaSaved
         } else {
            if (this.month) {
               this.fechaInner = this.parseYearMonth(this.fechaFormat)
            } else {
               this.fechaInner = this.parseDate(this.fechaFormat)
            }
         }
      },
      blurTxtFecha() {
         this.$refs.txtFecha.blur()
         this.menuFecha = false
      },
      reset() {
         this.menuFecha = false
         this.fechaInner = ''
         this.fechaFormat = ''
         this.fechaSaved = ''
         this.$emit('updateFecha', '')
      }
   }
}
</script>
